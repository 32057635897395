/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip,
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import * as EmailValidator from 'email-validator';
import { karboneApiService } from '../../../services/karboneApiService';
import moment from 'moment';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default class UserEdit extends React.Component {
    state = {
        user: this.props.location.state.user,
        displayDeleteModal: false,
        displayUpdateModal: false,
        displayUploadDocumentModal: false,
        redirect: false,
        updatedUser: {
            email: this.props.location.state.user.email,
            firstname: this.props.location.state.user.firstname,
            lastname: this.props.location.state.user.lastname,
            phone: this.props.location.state.user.phone,
            isAdmin: this.props.location.state.user.isAdmin,
            isSimulationAdmin: this.props.location.state.user.isSimulationAdmin,
            isContactAdmin: this.props.location.state.user.isContactAdmin,
        },
        isError: {
            email: false,
            firstname: false,
            lastname: false,
            phone: false
        }
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Utilisateurs" parentName="Karbone" />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">{this.state.user.firstname + ' ' + this.state.user.lastname.toUpperCase()}</h3>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="updateButton"
                                    onClick={() => this.setState({ displayUpdateModal: true })}>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-user-edit" />
                                    </span>
                                    Mettre à jour
                                </Button>
                                <UncontrolledTooltip delay={0} target="updateButton">
                                    Enregistrer les modifications
                                </UncontrolledTooltip>
                                <Button color="danger" size="sm" type="button"
                                    id="deleteButton"
                                    onClick={() => this.setState({ displayDeleteModal: true })}>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-trash" />
                                    </span>
                                    Supprimer
                                </Button>
                                <UncontrolledTooltip delay={0} target="deleteButton">
                                    Supprimer l'élement
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Adresse e-mail</Label>
                                        <Input
                                            id="email"
                                            placeholder="E-mail"
                                            type="email"
                                            className={this.state.isError.email ? "is-invalid" : ""}
                                            defaultValue={this.state.user.email}
                                            onChange={element => this.setState({
                                                updatedUser: {
                                                    ...this.state.updatedUser,
                                                    email: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            L'adresse e-mail est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Téléphone</Label>
                                        <Input
                                            id="phone"
                                            placeholder="Téléphone"
                                            type="tel"
                                            maxLength={10}
                                            defaultValue={this.state.user.phone}
                                            className={this.state.isError.phone ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                updatedUser: {
                                                    ...this.state.updatedUser,
                                                    phone: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le téléphone est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Prénom</Label>
                                        <Input
                                            id="firstname"
                                            placeholder="Prénom"
                                            type="text"
                                            className={this.state.isError.firstname ? "is-invalid" : ""}
                                            defaultValue={this.state.user.firstname}
                                            onChange={element => this.setState({
                                                updatedUser: {
                                                    ...this.state.updatedUser,
                                                    firstname: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le prénom est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Nom</Label>
                                        <Input
                                            id="lastname"
                                            placeholder="Nom de famille"
                                            type="text"
                                            className={this.state.isError.lastname ? "is-invalid" : ""}
                                            defaultValue={this.state.user.lastname}
                                            onChange={element => this.setState({
                                                updatedUser: {
                                                    ...this.state.updatedUser,
                                                    lastname: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le nom de famille est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className="custom-control custom-checkbox mb-3">
                                        <input
                                            className="custom-control-input"
                                            id="checkboxIsAdmin"
                                            type="checkbox"
                                            defaultChecked={this.state.user.isAdmin === 0 ? false : true}
                                            onChange={element => this.setState({
                                                updatedUser: {
                                                    ...this.state.updatedUser,
                                                    isAdmin: element.target.checked
                                                }
                                            })}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="checkboxIsAdmin"
                                            id="isAdmin"
                                        >
                                            Administrateur général
                                        </Label>
                                        <UncontrolledTooltip delay={0} target="isAdmin">
                                            L'administrateur général a un accès à l'ensemble des fonctionnalités et peut gérer les utilisateurs
                                        </UncontrolledTooltip>
                                    </div>
                                    <div className="custom-control custom-checkbox mb-3">
                                        <input
                                            className="custom-control-input"
                                            id="checkboxIsSimulationAdmin"
                                            type="checkbox"
                                            defaultChecked={this.state.user.isSimulationAdmin === 0 ? false : true}
                                            onChange={element => this.setState({
                                                updatedUser: {
                                                    ...this.state.updatedUser,
                                                    isSimulationAdmin: element.target.checked
                                                }
                                            })}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="checkboxIsSimulationAdmin"
                                            id="isSimulationAdmin"
                                        >
                                            Administration des simulations
                                        </Label>
                                        <UncontrolledTooltip delay={0} target="isSimulationAdmin">
                                            Si coché, l'utilisateur peut accéder aux simulations
                                        </UncontrolledTooltip>
                                    </div>

                                    <div className="custom-control custom-checkbox mb-3">
                                        <input
                                            className="custom-control-input"
                                            id="checkboxIsContactAdmin"
                                            type="checkbox"
                                            defaultChecked={this.state.user.isContactAdmin === 0 ? false : true}
                                            onChange={element => this.setState({
                                                updatedUser: {
                                                    ...this.state.updatedUser,
                                                    isContactAdmin: element.target.checked
                                                }
                                            })}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="checkboxIsContactAdmin"
                                            id="isContactAdmin"
                                        >
                                            Administration des demandes de contact
                                        </Label>
                                        <UncontrolledTooltip delay={0} target="isContactAdmin">
                                            Si coché, l'utilisateur peut accéder aux demandes de contact
                                        </UncontrolledTooltip>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className="text-muted">Inscrit le {moment(this.state.user.createdAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                </Col>
                            </Row>
                            {this.state.user.updatedAt !== this.state.user.createdAt &&
                                <Row>
                                    <Col>
                                        <h5 className="text-muted">Mis à jour le {moment(this.state.user.updatedAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</h5>
                                    </Col>
                                </Row>}
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-danger"
                        isOpen={this.state.displayDeleteModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point du supprimer l'utilisateur {this.state.user.firstname + ' ' + this.state.user.lastname.toUpperCase()},
                                    cette action est irréversible.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.deleteUser()}
                            >
                                Supprimer
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>

                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-info"
                        isOpen={this.state.displayUpdateModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayUpdateModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point de mettre à jour l'utilisateur {this.state.user.firstname + ' ' + this.state.user.lastname.toUpperCase()},
                                    cette action est irréversible.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.updateUser()}
                            >
                                Mettre à jour
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayUpdateModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/users" />}
                </Container>
            </>
        );
    }

    deleteUser = async () => {
        try {
            const URL = `/users/delete/${this.state.user.id}`;
            this.setState({ displayDeleteModal: false });

            let response = await karboneApiService
                .request({
                    url: URL,
                    method: 'DELETE'
                });

            if (response.data.result) {
                this.notify('success', 'L\'utilisateur a été supprimé.');
                setTimeout(() => {
                    this.setState({ redirect: true })
                }, 1000)
            } else {
                throw new Error();
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                this.notify('warning', 'Permissions insuffisantes');
            } else {
                this.notify('danger', 'Il y a eu une erreur lors de la suppression.');
            }
        }
    }

    updateUser = async () => {
        try {
            const URL = `/users/update/${this.state.user.id}`;
            this.setState({ displayUpdateModal: false });

            if (this.checkInputs()) {
                let updatedData =
                {
                    "email": this.state.updatedUser.email,
                    "firstname": this.state.updatedUser.firstname,
                    "lastname": this.state.updatedUser.lastname,
                    "phone": this.state.updatedUser.phone,
                    "isAdmin": this.state.updatedUser.isAdmin,
                    "isSimulationAdmin": this.state.updatedUser.isSimulationAdmin,
                    "isContactAdmin": this.state.updatedUser.isContactAdmin,
                };

                let response = await karboneApiService
                    .request({
                        url: URL,
                        method: 'POST',
                        data: updatedData
                    });

                if (response.data.result) {
                    this.notify('success', 'L\'utilisateur a été mis à jour.');
                } else {
                    throw new Error();
                }
            } else {
                this.notify('warning', 'Votre saisie est incorrecte.');
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                this.notify('warning', 'Permissions insuffisantes');
            } else {
                this.notify('danger', 'Il y a eu une erreur lors de la mise à jour.');
            }
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { updatedUser } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(updatedUser)) {
            switch (key) {
                case 'firstname':
                    isError[key] = (/\d/.test(value) || value.length < 2);
                    break;
                case 'lastname':
                    isError[key] = (/\d/.test(value) || value.length < 2);
                    break;
                case 'email':
                    isError[key] = !EmailValidator.validate(this.state.updatedUser[key]);
                    break;
                // case 'phone':
                //     isError[key] = value == null || value.length !== 10 || isNaN(value);
                //     break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }

    notify = (type, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        Notification
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3,
        };
        this.notification.notificationAlert(options);
    };
}
