/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";
import UserAvatar from 'react-user-avatar';
import CardsHeader from "components/Headers/CardsHeader.js";
import { karboneApiService } from '../../services/karboneApiService';
import {
  chartOptions,
  parseOptions,
  chartAnalyses,
  chartAlerts,
} from "variables/charts.js";
import classnames from "classnames";

function Dashboard() {
  const [activeNav, setActiveNav] = React.useState(1);
  const [statistics, setStatistics] = React.useState([]);
  const [weekSimulationsStatistics, setWeekSimulationsStatistics] = React.useState([]);
  const [yearSimulationsStatistics, setYearSimulationsStatistics] = React.useState([]);
  const [yearContactStatistics, setYearContactStatistics] = React.useState([]);
  const [simulationChartData, setSimulationChartData] = React.useState([]);
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setSimulationChartData(index === 1 ? yearSimulationsStatistics: weekSimulationsStatistics);
  };

  useEffect(() => {
    async function getStatistics() {
      let response = await karboneApiService
        .request({
          url: '/statistics',
          method: 'GET'
        });
      setStatistics(response.data.result);

      response = await karboneApiService
        .request({
          url: '/statistics/simulations?date=week',
          method: 'GET'
        });
      setWeekSimulationsStatistics(response.data.result);

      response = await karboneApiService
        .request({
          url: '/statistics/simulations?date=year',
          method: 'GET'
        });
      setYearSimulationsStatistics(response.data.result);
      setSimulationChartData(response.data.result);

      response = await karboneApiService
        .request({
          url: '/statistics/contacts',
          method: 'GET'
        });
      setYearContactStatistics(response.data.result);
    }
    getStatistics();
  }, [])
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  return (
    <>
      <CardsHeader name="Tableau de bord" parentName="Karbone" statistics={statistics} />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="8">
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-light text-uppercase ls-1 mb-1">
                      {weekSimulationsStatistics.length !== 0 ? 'Simulations' : 'Chargement...'}
                    </h6>
                    <h5 className="h3 text-white mb-0">Aujourd'hui</h5>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem className="mr-2 mr-md-0">
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Année</span>
                          <span className="d-md-none">A</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Semaine</span>
                          <span className="d-md-none">S</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={simulationChartData}
                    options={chartAnalyses.options}
                    id="chart-sales-dark"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      {yearSimulationsStatistics.length !== 0 ? 'Demandes de contact' : 'Chargement...'}
                    </h6>
                    <h5 className="h3 mb-0">Année</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={yearContactStatistics}
                    options={chartAlerts.options}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Administration</h5>
              </CardHeader>
              <CardBody>
                <ListGroup className="list my--3" flush>
                  {Object.keys(statistics).length > 0 && statistics.staff?.map((staff, index) => {
                    return (
                      <ListGroupItem className="px-0" key={index}>
                        <Row className="align-items-center mt--1">
                          <Col className="col-auto">
                            <div
                              className="avatar rounded-circle"
                              onClick={(e) => e.preventDefault()}
                            >
                              <UserAvatar size="48" name={staff.firstname + ' ' + staff.lastname} color="#8F8F8F" />

                            </div>
                          </Col>
                          <div className="col ml--2">
                            <h4 className="mb-0">
                              <div>
                                {staff.firstname + ' ' + staff.lastname}
                              </div>
                            </h4>
                            <span className="text-primary"><i className="far fa-envelope" /></span>{" "}
                            <small>{staff.email}</small>
                            <Row>
                              {staff.isAdmin === 1 &&
                                <span className="ml-3">
                                  <span className="text-primary"><i className="fas fa-user-shield" /></span>{" "}
                                  <small>Administrateur général</small>
                                </span>}
                              {(staff.isAdmin === 0 && staff.isSimulationAdmin === 1) &&
                                <span className="ml-3">
                                  <span className="text-primary"><i className="fas fa-receipt" /></span>{" "}
                                  <small>Simulations</small>
                                </span>}
                              {(staff.isAdmin === 0 && staff.isContactAdmin === 1) &&
                                <span className="ml-3">
                                  <span className="text-primary"><i className="fas fa-file-signature" /></span>{" "}
                                  <small>Contact</small>
                                </span>}
                            </Row>
                          </div>
                        </Row>
                      </ListGroupItem>
                    )
                  })}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
