import { karboneApiService } from '../services/karboneApiService';

async function Login(email, password, keepSignedIn) {
    try {
        let credentials = {
            "email": email,
            "password": password
        }

        let response = await karboneApiService
            .request({
                url: '/signin',
                method: 'POST',
                data: credentials,
            });

        if (response.data.result) {
            response = await karboneApiService
                .request({
                    url: '/userInfo',
                    method: 'GET',
                });
            if (response.statusText === 'OK') {
                sessionStorage.setItem('email', response.data.email);
                sessionStorage.setItem('fullname', response.data.firstname + ' ' + response.data.lastname);
                sessionStorage.setItem('isAdmin', response.data.isAdmin);
                sessionStorage.setItem('isSimulationAdmin', response.data.isSimulationAdmin);
                sessionStorage.setItem('isContactAdmin', response.data.isContactAdmin);

                if (keepSignedIn) {
                    localStorage.setItem('email', response.data.email);
                    localStorage.setItem('password', password);
                }
                return true;
            } else {
                throw new Error();
            }
        } else {
            throw new Error();
        }
    } catch (error) {
        return false;
    }
}

async function Logout() {
    try {
        localStorage.clear();
        sessionStorage.clear();
        await karboneApiService
            .request({
                url: '/logout',
                method: 'GET',
            });
    } catch (error) {

    }
}

const Auth = {
    Login,
    Logout
}

export default Auth;