/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Label,
    Button,
    Modal,
    UncontrolledTooltip
} from "reactstrap";
import { Redirect } from "react-router";
import NotificationAlert from "react-notification-alert";
import { karboneApiService } from '../../../services/karboneApiService';
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default class YearlyStatsAdd extends React.Component {
    state = {
        redirect: false,
        newYearlyStats: {
            year: new Date().getFullYear(),
            reforestationObjective: null,
            reforestationAchieved: null,
            carbonSavingsObjective: null,
            carbonSavingsAchieved: null
        },
        isError: {
            year: false,
            reforestationObjective: false,
            reforestationAchieved: false,
            carbonSavingsObjective: false,
            carbonSavingsAchieved: false
        },
        displayConfirmModal: false
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Statistiques annuelles" parentName="Karbone" />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="float-left">
                                <h3 className="mb-0">Ajouter une année</h3>
                            </div>
                            <div className="float-right">
                                <Button color="success" size="sm" type="button"
                                    id="createButton"
                                    onClick={() => this.setState({ displayConfirmModal: true })}
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-plus" />
                                    </span>
                                    Ajouter
                                </Button>
                                <UncontrolledTooltip delay={0} target="createButton">
                                    Ajouter une année
                                </UncontrolledTooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Objectif reboisement</Label>
                                        <Input
                                            id="reforestationObjective"
                                            placeholder="2000 (arbres)"
                                            type="number"
                                            className={this.state.isError.reforestationObjective ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newYearlyStats: {
                                                    ...this.state.newYearlyStats,
                                                    reforestationObjective: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            L'objectif reboisement est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Reboisement réalisé</Label>
                                        <Input
                                            id="reforestationAchieved"
                                            placeholder="2000 (arbres)"
                                            type="number"
                                            className={this.state.isError.reforestationAchieved ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newYearlyStats: {
                                                    ...this.state.newYearlyStats,
                                                    reforestationAchieved: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Le reboisement réalisé est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Objectif économie de CO2</Label>
                                        <Input
                                            id="carbonSavingsObjective"
                                            placeholder="2000 (tonnes)"
                                            type="number"
                                            className={this.state.isError.carbonSavingsObjective ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newYearlyStats: {
                                                    ...this.state.newYearlyStats,
                                                    carbonSavingsObjective: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            L'objectif économie de CO2 est incorrect.
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Economie de CO2 réalisées</Label>
                                        <Input
                                            id="carbonSavingsAchieved"
                                            placeholder="2000 (tonnes)"
                                            type="number"
                                            className={this.state.isError.carbonSavingsAchieved ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newYearlyStats: {
                                                    ...this.state.newYearlyStats,
                                                    carbonSavingsAchieved: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            Les économies de CO2 réalisées sont incorrectes.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>Année</Label>
                                        <Input
                                            id="year"
                                            defaultValue={this.state.newYearlyStats.year}
                                            maxLength={4}
                                            className={this.state.isError.year ? "is-invalid" : ""}
                                            onChange={element => this.setState({
                                                newYearlyStats: {
                                                    ...this.state.newYearlyStats,
                                                    year: element.target.value
                                                }
                                            })}
                                        />
                                        <div className="invalid-feedback">
                                            L'année est incorrecte.
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-success"
                        isOpen={this.state.displayConfirmModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Confirmation!
                                </h4>
                                <p>
                                    Vous allez ajouter une année.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.addYearlyStats()}
                            >
                                Ajouter
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayConfirmModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                    {this.state.redirect && <Redirect to="/admin/yearlystats" />}
                </Container >
            </>
        );
    }

    addYearlyStats = async () => {
        try {
            const URL = `/yearlystats/create`;
            this.setState({ displayConfirmModal: false });

            if (this.checkInputs()) {
                let response = await karboneApiService
                    .request({
                        url: URL,
                        method: 'PUT',
                        data: this.state.newYearlyStats
                    });

                if (response.data.result) {
                    this.notify('success', `L'année ${this.state.newYearlyStats.year} a été ajoutée.`);
                } else {
                    throw new Error();
                }
            } else {
                this.notify('warning', 'Votre saisie est incorrecte.');
            }
        } catch (error) {
            if (error.message.endsWith('409')) {
                this.notify('warning', `Les statistiques de l'année ${this.state.newYearlyStats.year} existent déjà.`);
            } else {
                this.notify('danger', 'Il y a eu une erreur lors de l\'ajout.');
            }
        }
    }

    checkInputs = () => {
        let { isError } = this.state;
        let { newYearlyStats } = this.state;
        let areInputsCorrect = true;

        for (const [key, value] of Object.entries(newYearlyStats)) {
            switch (key) {
                case 'year':
                    isError[key] = isNaN(value) || value == null || value.toString().length !== 4;
                    break;
                case 'reforestationObjective':
                    isError[key] = isNaN(value) || value == null || value.length < 1;
                    break;
                case 'reforestationAchieved':
                    isError[key] = isNaN(value) || value == null || value.length < 1;
                    break;
                case 'carbonSavingsObjective':
                    isError[key] = isNaN(value) || value == null || value.length < 1;
                    break;
                case 'carbonSavingsAchieved':
                    isError[key] = isNaN(value) || value == null || value.length < 1;
                    break;
                default:
                    break;
            }
        }
        this.setState({ isError });

        // Retourne false si un des champs est incorrect
        for (const [key, value] of Object.entries(isError)) {
            if (value) areInputsCorrect = false;
            if (key) { }
        }

        return areInputsCorrect;
    }

    notify = (type, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        Notification
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3,
        };
        this.notification.notificationAlert(options);
    };
}
