import paginationFactory from "react-bootstrap-table2-paginator";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: false,
    withFirstAndLast: false,
    nextPageTitle: 'Page suivante',
    prePageTitle: 'Page précédente',
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Afficher{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                éléments
            </label>
        </div>
    ),
});

export default pagination;