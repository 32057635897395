import axios from 'axios';

const URL = process.env.REACT_APP_API_URL ?? 'http://localhost:3001/api';
// const URL = 'https://karbone-api.vercel.app/api';

const apiService = axios.create({
  withCredentials: true,
  baseURL: URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const karboneApiService = apiService;
export const baseURL = URL;
