/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    UncontrolledTooltip,
    Container,
    Row,
    Button
} from "reactstrap";
// import { Link } from "react-router-dom";
import Utils from "../../../utils/index";
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "components/Buttons/Pagination";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { karboneApiService } from '../../../services/karboneApiService';
import { saveAs } from 'file-saver';

const { SearchBar } = Search;

export default class Simulations extends React.Component {
    state = {
        simulations: [],
        apiError: false
    }

    async componentDidMount() {
        const simulations = await Utils.GetSimulations();
        if (simulations !== null) {
            this.setState({ simulations: simulations });
        } else {
            this.setState({ apiError: true });
        }
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Simulations" parentName="Karbone" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Simulations</h3>
                                    </div>
                                    <div className="float-right">
                                        <Button color="primary" size="sm" type="button" id="exportButton"
                                            onClick={this.exportToCSV}>
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-file-export" />
                                            </span>
                                            Exporter
                                        </Button>
                                        <UncontrolledTooltip delay={0} target="exportButton" >
                                            Exporter au format CSV
                                        </UncontrolledTooltip>
                                    </div>
                                </CardHeader>
                                <ToolkitProvider
                                    data={this.state.simulations}
                                    keyField="id"
                                    columns={[
                                        {
                                            dataField: "id",
                                            text: "#",
                                            sort: false,
                                        },
                                        {
                                            dataField: "company",
                                            text: "Société",
                                            sort: true,
                                            formatter: (company) => {
                                                return company.charAt(0).toUpperCase() + company.slice(1);
                                            },
                                        },
                                        {
                                            dataField: "email",
                                            text: "Email",
                                            sort: true
                                        },
                                        {
                                            dataField: "width",
                                            text: "Largeur",
                                            sort: false,
                                            formatter: (value) => {
                                                return value + 'm';
                                            },
                                        },
                                        {
                                            dataField: "height",
                                            text: "Hauteur",
                                            sort: false,
                                            formatter: (value) => {
                                                return value + 'm';
                                            },
                                        },
                                        {
                                            dataField: "distance",
                                            text: "Distance",
                                            sort: false,
                                            formatter: (value) => {
                                                return value + 'kms';
                                            },
                                        },
                                        {
                                            dataField: "createdAt",
                                            text: "Date",
                                            sort: true,
                                            formatter: (value) => {
                                                return moment(value).locale('fr').format('DD/MM/YYYY à HH[h]mm');
                                            },
                                        },
                                        // {
                                        //     dataField: "dummyFieldActions",
                                        //     isDummyField: true,
                                        //     text: "Actions",
                                        //     sort: false,
                                        //     formatter: (value, simulation, index) => {
                                        //         return (
                                        //             <div>
                                        //                 <Link className="table-action" id={`editElement${index}`} to={{
                                        //                     pathname: "/admin/simulation/edit",
                                        //                     state: { simulation: simulation }
                                        //                 }}>
                                        //                     <i className="fas fa-edit" />
                                        //                 </Link>
                                        //                 <UncontrolledTooltip delay={0} target={`editElement${index}`} >
                                        //                     Éditer
                                        //                 </UncontrolledTooltip>
                                        //             </div>
                                        //         )
                                        //     }
                                        // },
                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 table-responsive">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1"
                                            >
                                                <label>
                                                    Recherche:
                                                    <SearchBar
                                                        className="form-control-sm"
                                                        placeholder="Recherche"
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                            <div className="d-flex justify-content-center">
                                                {this.state.apiError
                                                    ? (<h5 className="mb-0 text-danger">Erreur lors du chargement</h5>)
                                                    : (this.state.simulations.length < 1 && <h5 className="mb-0">Aucun élément</h5>)}
                                            </div>
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }

    exportToCSV = async () => {
        try {
            const URL = '/simulations/export';

            let response = await karboneApiService
                .request({
                    url: URL,
                    method: 'GET',
                    responseType: 'blob'
                });

            if (response.status === 204) {
                this.notify('warning', 'Aucune donnée à exporter.');
            } else if (response.status === 200) {
                this.notify('success', 'Génération du fichier CSV...');
                saveAs(response.data, 'Karbone - Simulations.csv');
            }
        } catch (error) {
            this.notify('danger', 'Erreur lors de la génération du fichier CSV.');
        }
    }

    notify = (type, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        Notification
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3,
        };
        this.notification.notificationAlert(options);
    };
}
