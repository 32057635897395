/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import UserAvatar from 'react-user-avatar';
import Auth from '../../authentication/Auth';
import { Redirect } from 'react-router-dom';

function TimelineHeader({ name, parentName }) {
  const [redirect, setRedirect] = useState(false);

  const LogoutHandler = async () => {
    await Auth.Logout();
    setRedirect(true);
  }

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col>
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  {name}
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="/admin/dashboard">
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col>
                <Nav className="align-items-end ml-auto ml-md-0" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle className="nav-link pr-0" color="" tag="a" style={{cursor: 'pointer'}}>
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <UserAvatar size="38" name={sessionStorage.getItem('fullname') || "John Doe"} color="#8F8F8F" />
                        </span>
                        <Media className="ml-2 d-none d-lg-block" onMouseOver={({ target }) => target.style.color = '#DBDBDB'} onMouseOut={({ target }) => target.style.color = 'white'}>
                          <span className="mb-0 pt-3 pb-3 text-sm font-weight-bold" style={{ color: 'white' }}>
                            {sessionStorage.getItem('fullname')}
                          </span>
                        </Media>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem className="noti-title" header tag="div">
                        <h6 className="text-overflow m-0">Bienvenue!</h6>
                      </DropdownItem>
                      {/* <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="ni ni-single-02" />
                        <span>Mon profil</span>
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="ni ni-settings-gear-65" />
                        <span>Paramètres</span>
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="ni ni-calendar-grid-58" />
                        <span>Activités</span>
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="ni ni-support-16" />
                        <span>Support</span>
                      </DropdownItem> */}
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={async () => LogoutHandler()}
                      >
                        <i className="ni ni-user-run" />
                        <span>Déconnexion</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Col>
            </Row>
          </div>
          {redirect && <Redirect to={{ pathname: '/auth' }} />}
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
