/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    UncontrolledTooltip,
    Container,
    Row,
    Button
} from "reactstrap";
import { Link } from "react-router-dom";
import Utils from "../../../utils/index";
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "components/Buttons/Pagination";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";

const { SearchBar } = Search;

export default class Users extends React.Component {
    state = {
        users: [],
        apiError: false
    }

    async componentDidMount() {
        const users = await Utils.GetUsers();
        if (users !== null) {
            this.setState({ users: users });
        } else {
            this.setState({ apiError: true });
        }
    }

    render() {
        return (
            <>
                <SimpleHeader name="Utilisateurs" parentName="Karbone" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Utilisateurs</h3>
                                    </div>
                                    <div className="float-right">
                                        <Link className="table-action" to={{
                                            pathname: "/admin/user/add"
                                        }} >
                                            <Button color="success" size="sm" type="button"
                                                id="createButton">

                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-plus" />
                                                </span>
                                                Ajouter
                                            </Button>
                                            <UncontrolledTooltip delay={0} target="createButton" >
                                                Créer un nouveau compte utilisateur
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                </CardHeader>
                                <ToolkitProvider
                                    data={this.state.users}
                                    keyField="email"
                                    columns={[
                                        {
                                            dataField: "lastname",
                                            text: "Nom",
                                            sort: true,
                                            formatter: (lastname) => {
                                                return lastname.toUpperCase();
                                            },
                                        },
                                        {
                                            dataField: "firstname",
                                            text: "Prénom",
                                            sort: true,
                                            formatter: (firstname) => {
                                                return firstname.charAt(0).toUpperCase() + firstname.slice(1);
                                            },
                                        },
                                        {
                                            dataField: "email",
                                            text: "Email",
                                            sort: false,
                                        },
                                        {
                                            dataField: "phone",
                                            text: "Téléphone",
                                            sort: false,
                                            formatter: (phone) => {
                                                return typeof phone === 'string' ? phone.replace(/(.{2})/g, "$1 ") : 'Aucun';
                                            },
                                        },
                                        {
                                            dataField: "isAdmin",
                                            text: "Admin général",
                                            sort: true,
                                            formatter: (value) => {
                                                return value === 0 ? 'Non' : 'Oui';
                                            },
                                        },
                                        {
                                            dataField: "isSimulationAdmin",
                                            text: "Admin simulations",
                                            sort: true,
                                            formatter: (value) => {
                                                return value === 0 ? 'Non' : 'Oui';
                                            },
                                        },
                                        {
                                            dataField: "isContactAdmin",
                                            text: "Admin contact",
                                            sort: true,
                                            formatter: (value) => {
                                                return value === 0 ? 'Non' : 'Oui';
                                            },
                                        },
                                        {
                                            dataField: "createdAt",
                                            text: "Date d'ajout",
                                            sort: true,
                                            formatter: (value) => {
                                                return moment(value).locale('fr').format('DD/MM/YYYY');
                                            },
                                        },
                                        {
                                            dataField: "dummyFieldActions",
                                            isDummyField: true,
                                            text: "Actions",
                                            sort: false,
                                            formatter: (value, user, index) => {
                                                return (
                                                    <div>
                                                        <Link className="table-action" id={`editElement${index}`} to={{
                                                            pathname: "/admin/user/edit",
                                                            state: { user: user }
                                                        }}>
                                                            <i className="fas fa-user-edit" />
                                                        </Link>
                                                        <UncontrolledTooltip delay={0} target={`editElement${index}`} >
                                                            Éditer
                                                        </UncontrolledTooltip>
                                                    </div>
                                                )
                                            }
                                        },
                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 table-responsive">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1"
                                            >
                                                <label>
                                                    Recherche:
                                                    <SearchBar
                                                        className="form-control-sm"
                                                        placeholder="Recherche"
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                            <div className="d-flex justify-content-center">
                                                {this.state.apiError
                                                    ? (<h5 className="mb-0 text-danger">Erreur lors du chargement</h5>)
                                                    : (this.state.users.length < 1 && <h5 className="mb-0">Aucun élément</h5>)}
                                            </div>
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}
