/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    Input,
    Col,
    FormGroup,
    CardBody,
    Modal,
    UncontrolledTooltip,
    Table
} from "reactstrap";
import moment from 'moment';
import 'moment/locale/fr';
import NotificationAlert from "react-notification-alert";
import * as EmailValidator from "email-validator";
import { karboneApiService } from '../../services/karboneApiService';
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default class Results extends React.Component {
    state = {
        settings: null,
        adminEmails: [],
        newEmail: null,
        emailToDelete: null,
        displayDeleteModal: false,
        apiError: false,
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    async componentDidMount() {
        await this.getSettings();
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Paramètres" parentName="Karbone" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h3 className="mb-0">Paramètres</h3>
                                            <h5 className="mt-2 text-muted">Dernière mise à jour {this.state.settings === null ? ': jamais' : 'le ' + moment(this.state.settings.updatedAt).format('dddd Do MMMM [à] HH[h]mm')}</h5>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Card>
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col>
                                                    <h3 className="mb-0">Email administrateurs</h3>
                                                    <h5 className="text-muted">Ces adresses emails reçoivent des alertes lorsqu'une demande de contact est créée.</h5>
                                                </Col>
                                            </Row>
                                        </CardHeader>

                                        <FormGroup>
                                            <Row style={{ alignItems: 'center', marginLeft: '10px' }}>
                                                <Col xs="10">
                                                    <label className="form-control-label">Ajouter une adresse </label>
                                                    <Input
                                                        placeholder="john.doe@gmail.com"
                                                        type="text"
                                                        onChange={element => this.setState({ newEmail: element.target.value })}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Row style={{ justifyContent: 'center' }}>
                                                        <Button color="success" type="button"
                                                            onClick={() => this.addAdminEmail()}>
                                                            <span className="btn-inner--icon mr-1">
                                                                <i className="fas fa-plus" />
                                                            </span>
                                                            Ajouter
                                                        </Button>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </FormGroup>

                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Adresse</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.adminEmails.map(email => {
                                                    return (
                                                        <tr key={email}>
                                                            <td className="table-user">
                                                                <b>{email}</b>
                                                            </td>
                                                            <td className="table-actions">
                                                                <a
                                                                    className="table-action table-action-delete"
                                                                    href="#karbone"
                                                                    id="tooltip601065234"
                                                                    onClick={() => this.setState({ displayDeleteModal: true, emailToDelete: email })}
                                                                >
                                                                    <i className="fas fa-trash" />
                                                                </a>
                                                                <UncontrolledTooltip delay={0} target="tooltip601065234">
                                                                    Supprimer
                                                                </UncontrolledTooltip>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Card>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                    <Modal
                        className="modal-dialog-centered modal-danger"
                        contentClassName="bg-gradient-danger"
                        isOpen={this.state.displayDeleteModal}
                    >
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="modal-title-notification"
                            >
                                Confirmation
                            </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="py-3 text-center">
                                <i className="ni ni-bell-55 ni-3x" />
                                <h4 className="heading mt-4">
                                    Attention!
                                </h4>
                                <p>
                                    Vous êtes sur le point de mettre à jour les paramètres.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn-white"
                                color="default"
                                type="button"
                                onClick={() => this.removeAdminEmail()}
                            >
                                Continuer
                            </Button>
                            <Button
                                className="text-white ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ displayDeleteModal: false })}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Modal>
                </Container>
            </>
        );
    }

    addAdminEmail = async () => {
        try {
            if (EmailValidator.validate(this.state.newEmail)) {
                const URL = `/settings/adminEmails/add`;

                let response = await karboneApiService
                    .request({
                        url: URL,
                        method: 'POST',
                        data: {
                            "email": this.state.newEmail
                        }
                    });

                if (response.data.result) {
                    this.notify('success', 'Les paramètres ont été mis à jour.');
                    await this.getSettings();
                } else {
                    throw new Error();
                }
            } else {
                this.notify('danger', 'L\'adresse email saisie est invalide.');
            }
        } catch (error) {
            if (error.message.endsWith('409')) {
                this.notify('warning', 'Cette adresse email est déjà dans la liste.');
            } else {
                this.notify('danger', 'Il y a eu une erreur lors de la mise à jour.');
            }
        }
    }

    removeAdminEmail = async () => {
        try {
            this.setState({ displayDeleteModal: false });
            const URL = `/settings/adminEmails/remove`;

            let response = await karboneApiService
                .request({
                    url: URL,
                    method: 'DELETE',
                    data: {
                        "email": this.state.emailToDelete
                    }
                });

            if (response.data.result) {
                this.notify('success', 'L\'adresse a été supprimée.');
                await this.getSettings();
            } else {
                throw new Error();
            }
        } catch (error) {
            this.notify('danger', 'Il y a eu une erreur lors de la mise à jour.');
        }
    }

    getSettings = async () => {
        let response = await karboneApiService
            .request({
                url: '/settings',
                method: 'GET'
            });

        if (response.data.result) {
            this.setState({ settings: response.data.result },
                async () => {
                    let adminEmails = [];
                    this.state.settings.adminEmails.split(',').forEach(email => email.length > 0 && adminEmails.push(email));
                    this.setState({ adminEmails: adminEmails });
                });
        }
    }

    notify = (type, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        Notification
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3,
        };
        this.notification.notificationAlert(options);
    };
}