/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    UncontrolledTooltip,
    Container,
    Row,
    Button
} from "reactstrap";
import { Link } from "react-router-dom";
import Utils from "../../../utils/index";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "components/Buttons/Pagination";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";

const { SearchBar } = Search;

export default class YearlyStats extends React.Component {
    state = {
        stats: [],
        apiError: false
    }

    async componentDidMount() {
        const yearlyStats = await Utils.GetYearlyStats();
        if (yearlyStats !== null) {
            this.setState({ stats: yearlyStats });
        } else {
            this.setState({ apiError: true });
        }
    }

    render() {
        return (
            <>
                <SimpleHeader name="Statistiques annuelles" parentName="Karbone" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Statistiques annuelles</h3>
                                    </div>
                                    <div className="float-right">
                                        <Link className="table-action" to={{
                                            pathname: "/admin/yearlystat/add"
                                        }} >
                                            <Button color="success" size="sm" type="button"
                                                id="createButton">

                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-plus" />
                                                </span>
                                                Ajouter
                                            </Button>
                                            <UncontrolledTooltip delay={0} target="createButton" >
                                                Ajouter pour une nouvelle année
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                </CardHeader>
                                <ToolkitProvider
                                    data={this.state.stats}
                                    keyField="id"
                                    columns={[
                                        {
                                            dataField: "year",
                                            text: "Année",
                                            sort: true
                                        },
                                        {
                                            dataField: "reforestationObjective",
                                            text: "Objectif reboisement",
                                            sort: true,
                                            formatter: (value) => {
                                                return value + ' arbres';
                                            },
                                        },
                                        {
                                            dataField: "reforestationAchieved",
                                            text: "Reboisement réalisé",
                                            sort: true,
                                            formatter: (value) => {
                                                return value + ' arbres';
                                            },
                                        },
                                        {
                                            dataField: "carbonSavingsObjective",
                                            text: "Objectif économie CO2",
                                            sort: true,
                                            formatter: (value) => {
                                                return value + ' tonnes';
                                            },
                                        },
                                        {
                                            dataField: "carbonSavingsAchieved",
                                            text: "Economie CO2 réalisées",
                                            sort: true,
                                            formatter: (value) => {
                                                return value + ' tonnes';
                                            },
                                        },
                                        {
                                            dataField: "dummyFieldActions",
                                            isDummyField: true,
                                            text: "Actions",
                                            sort: false,
                                            formatter: (value, stats, index) => {
                                                return (
                                                    <div>
                                                        <Link className="table-action" id={`editElement${index}`} to={{
                                                            pathname: "/admin/yearlystat/edit",
                                                            state: { yearlyStats: stats }
                                                        }}>
                                                            <i className="fas fa-edit" />
                                                        </Link>
                                                        <UncontrolledTooltip delay={0} target={`editElement${index}`} >
                                                            Modifier l'année {stats.year}
                                                        </UncontrolledTooltip>
                                                    </div>
                                                )
                                            }
                                        },
                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 table-responsive">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1"
                                            >
                                                <label>
                                                    Recherche:
                                                    <SearchBar
                                                        className="form-control-sm"
                                                        placeholder="Recherche"
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                            <div className="d-flex justify-content-center">
                                                {this.state.apiError
                                                    ? (<h5 className="mb-0 text-danger">Erreur lors du chargement</h5>)
                                                    : (this.state.stats.length < 1 && <h5 className="mb-0">Aucun élément</h5>)}
                                            </div>
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}
