/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Dashboard from "views/pages/Dashboard.js";
import Login from "views/pages/auth/Login.js";
import Users from "views/pages/users/Users.js";
import UserEdit from "views/pages/users/UserEdit.js";
import UserAdd from "views/pages/users/UserAdd.js";
import Simulations from "views/pages/simulations/Simulations.js";
import ContactRequests from "views/pages/contactRequests/ContactRequests.js";
import YearlyStats from "views/pages/yearlyStats/YearlyStats";
import YearlyStatsAdd from "views/pages/yearlyStats/YearlyStatsAdd";
import YearlyStatsEdit from "views/pages/yearlyStats/YearlyStatsEdit";
import Settings from "views/pages/Settings";
import Savings from "views/pages/Savings";

const routes = [
  {
    path: "/dashboard",
    name: "Accueil",
    component: Dashboard,
    icon: "fas fa-home text-primary",
    layout: "/admin"
  },
  {
    contactAdmin: true,
    path: "/contactrequests",
    name: "Demandes de contact",
    icon: "fas fa-file-signature text-red",
    component: ContactRequests,
    layout: "/admin",
  },
  {
    simulationAdmin: true,
    path: "/simulations",
    name: "Simulations",
    icon: "fas fa-receipt text-yellow",
    component: Simulations,
    layout: "/admin",
  },
  {
    admin: true,
    path: "/users",
    name: "Utilisateurs",
    icon: "ni ni-single-02 text-orange",
    component: Users,
    layout: "/admin",
  },
  {
    hidden: true,
    path: "/user/add",
    name: "Ajouter un utilisateur",
    component: UserAdd,
    layout: "/admin"
  },
  {
    hidden: true,
    path: "/user/edit",
    name: "Modifier l'utilisateur",
    component: UserEdit,
    layout: "/admin"
  },
  {
    admin: true,
    path: "/yearlystats",
    name: "Statistiques annuelles",
    icon: "fas fa-chart-bar text-green",
    component: YearlyStats,
    layout: "/admin",
  },
  {
    hidden: true,
    path: "/yearlystat/add",
    component: YearlyStatsAdd,
    layout: "/admin"
  },
  {
    hidden: true,
    path: "/yearlystat/edit",
    component: YearlyStatsEdit,
    layout: "/admin"
  },
  {
    admin: true,
    path: "/savings",
    name: "Economies",
    icon: "fas fa-file-invoice-dollar text-yellow",
    component: Savings,
    layout: "/admin",
  },
  {
    admin: true,
    path: "/settings",
    name: "Paramètres",
    icon: "ni ni-settings text-grey",
    component: Settings,
    layout: "/admin",
  },
  {
    hidden: true,
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
  },
];

export default routes;
