import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import AdminLayout from 'layouts/Admin.js';
import Auth from '../../authentication/Auth';
import { Label } from 'reactstrap';

export default class ProtectedRoute extends React.Component {
    state = {
        isAuthenticated: false,
        checkFinished: false
    }

    async componentDidMount() {
        // Si data dans session storage -> l'utilisateur est déjà connecté
        if (sessionStorage.getItem('email')) {
            this.setState({ isAuthenticated: true });
        }
        // Si pas de data dans session storage, mais dans local storage, l'utilisateur souhaite maintenir la connexion
        else if (localStorage.getItem('email')) {
            if (await Auth.Login(localStorage.getItem('email'), localStorage.getItem('password'))) {
                this.setState({ isAuthenticated: true });
            }
        }
        this.setState({ checkFinished: true });
    }

    render() {
        return (
            this.state.checkFinished ? (
                this.state.isAuthenticated ? (
                    // Si accès direct à /edit redirige vers la page concernée
                    (typeof this.props.location.state === 'undefined' && this.props.location.pathname.includes('/edit')) ? (
                        <Redirect to={{ pathname: this.props.location.pathname.replace('/edit', 's') }} />
                    ) : (<Route path="/admin" render={(props) => <AdminLayout {...props} />} />)

                ) : (<Redirect to={{ pathname: '/auth' }} />)
            ) : (<Label>Redirection...</Label>)
        )
    }
}