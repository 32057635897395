import { karboneApiService } from '../services/karboneApiService';

async function GetSimulations() {
    let data = [];
    try {
        let response = await karboneApiService
            .request({
                url: '/simulations',
                method: 'GET'
            });

        if (response.data.result !== null) {
            data = response.data.result;
        } else {
            throw new Error();
        }
    } catch (error) {
        data = null;
    }
    return data;
}

async function GetContactRequests() {
    let data = [];
    try {
        let response = await karboneApiService
            .request({
                url: '/contactRequests',
                method: 'GET'
            });

        if (response.data.result !== null) {
            data = response.data.result;
        } else {
            throw new Error();
        }
    } catch (error) {
        data = null;
    }
    return data;
}

async function GetUsers() {
    let data = [];
    try {
        let response = await karboneApiService
            .request({
                url: '/users',
                method: 'GET'
            });

        if (response.data.result !== null) {
            data = response.data.result;
        } else {
            throw new Error();
        }
    } catch (error) {
        data = null;
    }
    return data;
}

async function GetYearlyStats() {
    let data = [];
    try {
        let response = await karboneApiService
            .request({
                url: '/yearlyStats',
                method: 'GET'
            });

        if (response.data.result !== null) {
            data = response.data.result;
        } else {
            throw new Error();
        }
    } catch (error) {
        data = null;
    }
    return data;
}

const Utils = {
    GetSimulations,
    GetUsers,
    GetContactRequests,
    GetYearlyStats
}

export default Utils;