/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    FormGroup,
    CardBody,
    Modal,
    UncontrolledTooltip,
    Form,
    Label
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "components/Buttons/Pagination";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import 'moment/locale/fr';
import NotificationAlert from "react-notification-alert";
import { karboneApiService } from '../../services/karboneApiService';
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

const { SearchBar } = Search;

export default class Results extends React.Component {
    state = {
        savings: [],
        csvFile: null,
        displayUploadDocumentModal: false,
        apiError: false,
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    async componentDidMount() {
        await this.getSavings();
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Économies" parentName="Karbone" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Économies</h3>
                                    </div>
                                    <div className="float-right">
                                        <Button color="primary" size="sm" type="button" id="exportButton"
                                            onClick={() => this.setState({ displayUploadDocumentModal: true })}>
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-file-export" />
                                            </span>
                                            Importer
                                        </Button>
                                        <UncontrolledTooltip delay={0} target="exportButton" >
                                            Importer des chantiers au format CSV
                                        </UncontrolledTooltip>
                                    </div>
                                </CardHeader>
                                <ToolkitProvider
                                    data={this.state.savings}
                                    keyField="id"
                                    columns={[
                                        {
                                            dataField: "id",
                                            text: "#",
                                            sort: true
                                        },
                                        {
                                            dataField: "width",
                                            text: "Largeur",
                                            sort: true,
                                            formatter: (value) => {
                                                return value + 'm';
                                            },
                                        },
                                        {
                                            dataField: "height",
                                            text: "Hauteur",
                                            sort: true,
                                            formatter: (value) => {
                                                return value + 'm';
                                            },
                                        },
                                        {
                                            dataField: "distance",
                                            text: "Distance",
                                            sort: true,
                                            formatter: (value) => {
                                                return value + 'kms';
                                            },
                                        },
                                        {
                                            dataField: "year",
                                            text: "Année",
                                            sort: true,
                                        },
                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 table-responsive">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1"
                                            >
                                                <label>
                                                    Recherche:
                                                    <SearchBar
                                                        className="form-control-sm"
                                                        placeholder="Recherche"
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                            <div className="d-flex justify-content-center">
                                                {this.state.apiError
                                                    ? (<h5 className="mb-0 text-danger">Erreur lors du chargement</h5>)
                                                    : (this.state.savings.length < 1 && <h5 className="mb-0">Aucun élément</h5>)}
                                            </div>
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Card>
                        </div>
                    </Row>

                    <Modal
                        className="modal-dialog-centered"
                        size="sm"
                        isOpen={this.state.displayUploadDocumentModal}
                    >
                        <div className="modal-body p-0">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <small>Importer les chantiers au format CSV</small>
                                    </div>
                                    <Form role="form">
                                        <FormGroup>
                                            <Label>Fichier</Label>
                                            <Form>
                                                <div className="custom-file">
                                                    <input
                                                        className="custom-file-input"
                                                        id="customFileLang"
                                                        lang="fr"
                                                        type="file"
                                                        accept=".csv"
                                                        onChange={e => this.setState({ csvFile: e.target.files[0] })}
                                                    />
                                                    <label
                                                        className="custom-file-label"
                                                        htmlFor="customFileLang"
                                                    >
                                                    </label>
                                                </div>
                                            </Form>
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="button"
                                                onClick={() => this.uploadCSV()}
                                            >
                                                Envoyer
                                            </Button>
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="button"
                                                onClick={() => this.setState({ displayUploadDocumentModal: false })}
                                            >
                                                Fermer
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Modal>
                </Container>
            </>
        );
    }

    uploadCSV = async () => {
        try {
            if (this.state.csvFile !== null) {
                const URL = '/savings/import';
                this.setState({ displayUploadDocumentModal: false });

                let formData = new FormData();
                formData.append('file', this.state.csvFile);

                let response = await karboneApiService
                    .request({
                        url: URL,
                        method: 'POST',
                        data: formData
                    });

                if (response.data.result) {
                    this.notify('success', 'Les données du fichier ont été importées.');
                    await this.getSavings();
                } else {
                    throw new Error();
                }
            } else {
                this.notify('warning', 'Veuillez sélectionner un fichier CSV.');
            }
        } catch (error) {
            if (error.message.endsWith('400')) {
                this.notify('warning', 'Vous ne pouvez importer que des fichiers au format CSV.');
            } else {
                this.notify('danger', 'Il y a eu une erreur lors de l\'import.');
            }
        }
    }

    getSavings = async () => {
        try {
            let response = await karboneApiService
                .request({
                    url: '/savings',
                    method: 'GET'
                });

            if (response.data.result) {
                this.setState({ savings: response.data.result });
            }
        } catch (error) {
            this.setState({ apiError: true });
        }
    }

    notify = (type, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        Notification
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3,
        };
        this.notification.notificationAlert(options);
    };
}