/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    UncontrolledTooltip,
    Container,
    Row,
    Modal,
    CardBody,
    Button
} from "reactstrap";
import Utils from "../../../utils/index";
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "components/Buttons/Pagination";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationAlert from "react-notification-alert";
import { karboneApiService } from '../../../services/karboneApiService';
import { saveAs } from 'file-saver';

const { SearchBar } = Search;

export default class ContactRequests extends React.Component {
    state = {
        contactRequests: [],
        selectedSimulation: null,
        selectedContactRequest: null,
        apiError: false
    }

    async componentDidMount() {
        await this.getContactRequests();
    }

    async getContactRequests() {
        const contactRequests = await Utils.GetContactRequests();
        if (contactRequests !== null) {
            this.setState({ contactRequests: contactRequests });
        } else {
            this.setState({ apiError: true });
        }
    }

    constructor(props) {
        super(props);
        this.notification = React.createRef();
    }

    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref => this.notification = ref} />
                </div>
                <SimpleHeader name="Demandes de contact" parentName="Karbone" />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader>
                                    <div className="float-left">
                                        <h3 className="mb-0">Demandes de contact</h3>
                                    </div>
                                    <div className="float-right">
                                        <Button color="primary" size="sm" type="button" id="exportButton"
                                            onClick={this.exportToCSV}>
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-file-export" />
                                            </span>
                                            Exporter
                                        </Button>
                                        <UncontrolledTooltip delay={0} target="exportButton" >
                                            Exporter au format CSV
                                        </UncontrolledTooltip>
                                    </div>
                                </CardHeader>
                                <ToolkitProvider
                                    data={this.state.contactRequests}
                                    keyField="id"
                                    columns={[
                                        {
                                            dataField: "id",
                                            text: "#",
                                            sort: false,
                                        },
                                        {
                                            dataField: "checked",
                                            text: "Traitée",
                                            sort: true,
                                            formatter: (value) => {
                                                return value ? 'Oui' : 'Non';
                                            }
                                        },
                                        {
                                            dataField: "company",
                                            text: "Société",
                                            sort: true,
                                            formatter: (company) => {
                                                return company.charAt(0).toUpperCase() + company.slice(1);
                                            },
                                        },
                                        {
                                            dataField: "email",
                                            text: "Email",
                                            sort: true
                                        },
                                        {
                                            dataField: "phone",
                                            text: "Téléphone",
                                            sort: false,
                                            formatter: (phone) => {
                                                return typeof phone === 'string' ? phone.replace(/(.{2})/g, "$1 ") : 'Aucun';
                                            },
                                        },
                                        {
                                            dataField: "firstname",
                                            text: "Prénom",
                                            sort: true,
                                            formatter: (firstname) => {
                                                return firstname.charAt(0).toUpperCase() + firstname.slice(1);
                                            },
                                        },
                                        {
                                            dataField: "lastname",
                                            text: "Nom",
                                            sort: true,
                                            formatter: (lastname) => {
                                                return lastname.toUpperCase();
                                            },
                                        },
                                        {
                                            dataField: "simulation",
                                            text: "Simulation",
                                            sort: true,
                                            formatter: (simulation, obj, index) => {
                                                return simulation == null ? 'Non' : 'Oui';
                                            },
                                        },
                                        {
                                            dataField: "createdAt",
                                            text: "Date",
                                            sort: true,
                                            formatter: (value) => {
                                                return moment(value).locale('fr').format('DD/MM/YYYY à HH[h]mm');
                                            },
                                        },
                                        {
                                            dataField: "dummyFieldActions",
                                            isDummyField: true,
                                            text: "Actions",
                                            sort: false,
                                            formatter: (value, contactRequest, index) => {
                                                return (
                                                    <>
                                                        <div className="table-action" >
                                                            {!contactRequest.checked && (<a href="#simulation"
                                                                style={{ cursor: 'pointer', color: '#b4b4bc' }}
                                                                id={`checkSimulation${index}`}
                                                                onClick={() => this.setState({ selectedContactRequest: contactRequest })}>
                                                                <i className="fas fa-check-square" style={{ marginRight: 10 }} />
                                                                <UncontrolledTooltip delay={0} target={`checkSimulation${index}`} >
                                                                    Marquer la demande comme traitée
                                                                </UncontrolledTooltip>
                                                            </a>)}

                                                            {contactRequest.simulation && (<a href="#simulation"
                                                                style={{ cursor: 'pointer', color: '#b4b4bc' }}
                                                                id={`showSimulation${index}`}
                                                                onClick={() => this.setState({ selectedSimulation: contactRequest.simulation })}>
                                                                <i className="fas fa-file" />
                                                                <UncontrolledTooltip delay={0} target={`showSimulation${index}`} >
                                                                    Afficher la simulation associée
                                                                </UncontrolledTooltip>
                                                            </a>)}

                                                        </div>
                                                    </>
                                                )
                                            }
                                        },
                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 table-responsive">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1"
                                            >
                                                <label>
                                                    Recherche:
                                                    <SearchBar
                                                        className="form-control-sm"
                                                        placeholder="Recherche"
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                            <div className="d-flex justify-content-center">
                                                {this.state.apiError
                                                    ? (<h5 className="mb-0 text-danger">Erreur lors du chargement</h5>)
                                                    : (this.state.contactRequests.length < 1 && <h5 className="mb-0">Aucun élément</h5>)}
                                            </div>
                                        </div>
                                    )}
                                </ToolkitProvider>
                                <Modal
                                    className="modal-dialog-centered"
                                    isOpen={this.state.selectedSimulation !== null}
                                    toggle={() => this.setState({ selectedSimulation: null })}
                                >
                                    <div className="modal-body p-0">
                                        <Card className="card-pricing border-0 text-center mb-1">
                                            <CardHeader className="bg-transparent">
                                                <h4 className="text-uppercase ls-1 text-primary py-3 mb-0">
                                                    Simulation Karbone #{this.state.selectedSimulation?.id}
                                                </h4>
                                                <div className="text-muted text-center">
                                                    <small>Simulation effectuée le {moment(this.state.selectedSimulation?.createdAt).locale('fr').format('DD/MM/YYYY à HH[h]mm')}</small>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="px-lg-7">
                                                <ul className="list-unstyled my-4">
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                                                    <i className="fas fa-building" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2 text-sm">
                                                                    Société: {this.state.selectedSimulation?.company}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                                                    <i className="fas fa-at" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2 text-sm">
                                                                    Adresse e-mail: {this.state.selectedSimulation?.email}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                                                    <i className="fas fa-arrows-alt-h" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2 text-sm">Largeur: {this.state.selectedSimulation?.width} mètres</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                                                    <i className="fas fa-arrows-alt-v" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2 text-sm">Hauteur: {this.state.selectedSimulation?.height} mètres</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                                                    <i className="fas fa-ruler-combined" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2 text-sm">Distance chantier: {this.state.selectedSimulation?.distance} kilomètres</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <Button className="mb-3" color="primary" type="button"
                                                    onClick={() => this.setState({ selectedSimulation: null })}>
                                                    Fermer
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Modal>
                                <Modal
                                    className="modal-dialog-centered modal-danger"
                                    contentClassName="bg-gradient-success"
                                    isOpen={this.state.selectedContactRequest !== null}
                                    toggle={() => this.setState({ selectedContactRequest: null })}
                                >
                                    <div className="modal-header">
                                        <h6
                                            className="modal-title"
                                            id="modal-title-notification"
                                        >
                                            Confirmation
                                        </h6>
                                        <button
                                            aria-label="Close"
                                            className="close"
                                            data-dismiss="modal"
                                            type="button"
                                            onClick={() => this.setState({ selectedContactRequest: null })}
                                        >
                                            <span aria-hidden={true}>×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="py-3 text-center">
                                            <i className="ni ni-bell-55 ni-3x" />
                                            <h4 className="heading mt-4">
                                                Attention!
                                            </h4>
                                            <p>
                                                Marquer cette demande de contact comme traitée ?
                                            </p>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <Button
                                            className="btn-white"
                                            color="default"
                                            type="button"
                                            onClick={() => this.updateContactRequest()}
                                        >
                                            Confirmer
                                        </Button>
                                        <Button
                                            className="text-white ml-auto"
                                            color="link"
                                            data-dismiss="modal"
                                            type="button"
                                            onClick={() => this.setState({ selectedContactRequest: null })}
                                        >
                                            Annuler
                                        </Button>
                                    </div>
                                </Modal>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }

    updateContactRequest = async () => {
        try {
            const URL = `/contactRequests/update/${this.state.selectedContactRequest.id}`;
            this.setState({ selectedContactRequest: null });

            let response = await karboneApiService
                .request({
                    url: URL,
                    method: 'POST',
                    data: { "checked": true }
                });

            if (response.data.result) {
                this.notify('success', 'Le demande de contact a été marquée comme traitée.');
                await this.getContactRequests();
            } else {
                throw new Error();
            }
        } catch (error) {
            if (error.message.endsWith('403')) {
                this.notify('warning', 'Permissions insuffisantes');
            } else {
                this.notify('danger', 'Il y a eu une erreur lors de la mise à jour.');
            }
        }
    }

    exportToCSV = async () => {
        try {
            const URL = '/contactRequests/export';

            let response = await karboneApiService
                .request({
                    url: URL,
                    method: 'GET',
                    responseType: 'blob'
                });

            if (response.status === 204) {
                this.notify('warning', 'Aucune donnée à exporter.');
            } else if (response.status === 200) {
                this.notify('success', 'Génération du fichier CSV...');
                saveAs(response.data, 'Karbone - Demandes de contact.csv');
            }
        } catch (error) {
            this.notify('danger', 'Erreur lors de la génération du fichier CSV.');
        }
    }

    notify = (type, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        Notification
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 3,
        };
        this.notification.notificationAlert(options);
    };
}
